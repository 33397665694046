<template>
  <div class="form-control">
    <div
      class="form-control__select"
      :class="{
        'form-control__select--placeholder': !element,
        'form-control__select--disabled': disabled,
      }"
      @click="visible = !visible"
    >
      <label
        class="form-control__select_label"
        :class="{ 'form-control__select_label--selected': element }"
      >
        {{ placeholder }}
        <span class="form-control__select_label_required" v-if="required">*</span>
      </label>
      <p class="text" :class="{ 'text--status': type === 'status', 'text--tag': type === 'tag' }">
        <span :class="`text--status_${element}`"></span>{{ element }}
      </p>
      <div class="icons">
        <span
          class="icon__close"
          v-if="close && element"
          @click="!disabled ? removeSelect() : false"
        >
          <IconClose width="8" height="8" viewBox="0 0 13 13"></IconClose>
        </span>
        <span class="icon" :class="{ 'icon--open': !disabled ? visible : false }">
          <IconArrowTop width="13" height="10"></IconArrowTop>
        </span>
      </div>
    </div>
    <div
      class="form-control__items"
      :class="{ 'form-control__items--visible': !disabled ? visible : false }"
    >
      <div class="form-control__items_body">
        <ul v-if="items.length > 0" class="list">
          <li v-for="i in items" :key="i.id">
            <a
              class="list__item"
              :class="{
                'list__item--active': element === i.title,
                'list__item--status': type === 'status',
              }"
              @click.prevent="
                $emit('input', {
                  id: i.id,
                  title: i.title,
                });
                visible = false;
              "
              ><span :class="`list__item--status_${i.title}`"></span>{{ i.title }}</a
            >
          </li>
        </ul>
        <p v-else>No possible options</p>
      </div>
    </div>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  props: {
    type: { type: String, required: false },
    items: { type: Array },
    selected: { type: [String, Number], required: false },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
    close: { type: Boolean, default: false },
  },
  components: { IconArrowTop, IconClose },
  data() {
    return {
      visible: false,
      element: "",
    };
  },
  created() {
    this.takeSelect();
  },
  watch: {
    selected() {
      this.takeSelect();
    },
  },
  methods: {
    takeSelect() {
      for (let el of this.items) {
        if (this.selected == el.id) {
          this.element = el.title;
        }
      }
    },
    removeSelect() {
      this.$emit("input", {
        id: null,
        title: "",
      });
      this.element = "";
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  &__select {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9rem;
    color: $lightBlack;
    background: $white;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    padding-left: 2.7rem;
    padding-right: 2.4rem;
    @include type();
    transition: all 0.3s ease;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
      font-size: 14px;
    }
    &--disabled {
      opacity: 0.6;
      cursor: default;
    }
    &_label {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);
      @include type($fw: 500);
      padding: 1px 1.1rem;
      background-color: $white;
      transition: all 0.1s ease;
      pointer-events: none;
      color: $black;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
      &--selected {
        top: 0;
      }
      &_required {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        color: $error;
        margin-left: 4px;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
      color: $lightBlack;
      @include type();
      &--status {
        position: relative;
        display: inline-flex;
        align-items: center;
        &_Inactive,
        &_Active,
        &_Published,
        &_Draft {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 12px;
        }
        &_Inactive {
          background-color: $light3Grey;
        }
        &_Draft {
          background-color: $orange;
        }
        &_Active,
        &_Published {
          background-color: $green;
        }
      }
      &--tag {
        padding: 6px 10px;
        border-radius: 6px;
        background-color: $blueLight;
        text-transform: capitalize;
        //text-transform: lowercase;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &--invalid {
    .form-control__select {
      border: 1px solid $error;
      color: $error;
    }
  }
  .icons {
    display: flex;
  }
  .icon {
    display: inline-flex;
    align-items: center;
    width: 1.85rem;
    height: 1.85rem;
    svg {
      transform: rotate(-180deg);
      transition: all 0.3s ease-in-out;
    }
    &--open svg {
      transform: rotate(0);
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 14px;
      height: 14px;
    }
    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.85rem;
      height: 1.85rem;
      border-radius: 4px;
      border: 1px solid $black;
      margin: 0 5px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 14px;
        height: 14px;
      }
      svg {
        width: auto;
        height: auto;
        &::v-deep g {
          fill: $black;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  &__items {
    width: calc(100% + 30px);
    position: absolute;
    left: -15px;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 8;
    padding: 10px 15px;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: $white;
    &--visible {
      visibility: visible;
      opacity: 1;
      max-height: 500px;
    }
    &_body {
      padding: 5px 0;
      border-radius: 12px;
      box-shadow: 0 2px 12px rgba($color: $black, $alpha: 0.25);
      .list {
        padding: 0px 5px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 280px;
        &__item {
          display: block;
          padding: 12px 15px;
          @include type();
          background-color: $white;
          cursor: pointer;
          border-radius: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $black;
          &:hover,
          &--active {
            background-color: $blueLight;
            color: $blue;
          }
          &--status {
            padding-left: 35px;
            &_Inactive,
            &_Active,
            &_Published,
            &_Draft {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: -25px;
                transform: translateY(-50%);
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 12px;
              }
            }
            &_Inactive:after {
              background-color: $light3Grey;
            }
            &_Draft:after {
              background-color: $orange;
            }
            &_Active,
            &_Published {
              &:after {
                background-color: $green;
              }
            }
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          a {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
      &>p{
        font-size: 14px;
      }
    }
  }
  &--grey {
    .form-control {
      &__select {
        background: transparent;
        &_label {
          background-color: #fbfaff;
        }
      }
      &__items {
        background-color: #fbfaff;
      }
    }
  }
  &--transparent {
    .form-control {
      &__select {
        background: rgb(29, 28, 28);
        border: 1px solid #ffffff;
        .text {
          color: #fff;
        }
        &_label {
          background-color: transparent;
          color: #fff;
          padding: 0;
          &--selected {
            display: none;
          }
        }
        .icon {
          svg {
            fill: #fff;
          }
        }
      }
      &__items {
        background: rgb(29, 28, 28);
        width: 100%;
        left: 0;
        border: 1px solid #ffffff;
        &_body {
          .list__item {
            background: transparent;
            color: #fff;
          }
        }
      }
    }
  }
  &--auto {
    width: 100%;
    .form-control__select {
      height: 5.2rem;
      border-radius: 4px;
      padding: 0 20px;
      &_label {
        left: 20px;
      }
    }
  }
  &--xs {
    min-width: 16rem;
    width: max-content;
    .form-control__select {
      height: 6rem;
      width: max-content;
      min-width: 16rem;
      border-radius: 8px;
      padding: 0 20px 0 10px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 5rem;
        font-size: 14px;
      }
      &_label {
        left: 10px;
      }
    }
  }
}
</style>

